<template>
  <nav class="main-header navbar navbar-expand navbar-dark border-bottom navbar-primary">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item"><a class="nav-link pushmenu" data-widget="pushmenu" href="#"><i class="fa fa-bars"></i></a></li>
      <li class="nav-item dropdown d-lg-none">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="fa fa-ellipsis-h"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-left dropdown-menu-sm-center">
          <ul class="list-unstyled navbar-blue">
            <li class="nav-item"><router-link to="/" class="nav-link">首页</router-link></li>
            <template v-if="setting">
              <li class="nav-item"><a class="nav-link" :href="setting.siteUrl" target="">网站首页</a></li>
              <li class="nav-item"><a class="nav-link" :href="setting.myUrl" target="">会员中心</a></li>
              <li v-if="$inRole('管理员')" class="nav-item"><a class="nav-link" :href="setting.adminUrl" target="">管理中心</a></li>
              <!--<li v-if="$inRole('管理员')||$inRole('公共管理')" class="nav-item"><a class="nav-link" :href="setting.myUrl+'/Public'" target="">公共管理</a></li>-->
              <li v-if="$inRole('管理员')||$inRole('客栈店主')||$inRole('客栈店员')" class="nav-item"><a class="nav-link" :href="setting.innAdminUrl+'/Inn'" target="">客栈管理</a></li>
              <li v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')" class="nav-item"><a class="nav-link" :href="setting.innAdminUrl+'/Agent'" target="">代理入口</a></li>
              <li v-if="$inRole('管理员')||$inRole('公共管理')" class="nav-item"><router-link :to="{ name: 'PublicHome'}" class="nav-link">公共管理</router-link></li>
              <li v-if="$inRole('管理员')||$inRole('用户组管理员')||$inRole('用户组成员')" class="nav-item"><router-link :to="{ name: 'GroupIndex'}" class="nav-link">项目管理</router-link></li>
            </template>
            <template v-else>
              <li class="nav-item"><a class="nav-link"><i class="fas fa-spinner fa-pulse"></i> 加载中…</a></li>
            </template>
          </ul>
        </div>
      </li>
      <li class="nav-item d-none d-lg-inline-block text-nowrap"><router-link to="/" class="nav-link">首页{{user}}</router-link></li>
      <template v-if="setting">
        <li class="nav-item d-none d-lg-inline-block text-nowrap"><a class="nav-link" :href="setting.siteUrl" target="">网站首页</a></li>
        <li class="nav-item d-none d-lg-inline-block text-nowrap"><a class="nav-link" :href="setting.myUrl" target="">会员中心</a></li>
        <li v-if="$inRole('管理员')" class="nav-item d-none d-lg-inline-block text-nowrap"><a class="nav-link" :href="setting.adminUrl" target="">管理中心</a></li>
        <!--<li v-if="$inRole('管理员')||$inRole('公共管理')" class="nav-item d-none d-lg-inline-block text-nowrap"><a class="nav-link" :href="setting.myUrl+'/Public'" target="">公共管理</a></li>-->
        <li v-if="$inRole('管理员')||$inRole('客栈店主')||$inRole('客栈店员')" class="nav-item d-none d-lg-inline-block text-nowrap"><a class="nav-link" :href="setting.innAdminUrl+'/Inn'" target="">客栈管理</a></li>
        <li v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')" class="nav-item d-none d-lg-inline-block text-nowrap"><a class="nav-link" :href="setting.innAdminUrl+'/Agent'" target="">代理入口</a></li>
        <li v-if="$inRole('管理员')||$inRole('公共管理')" class="nav-item d-none d-lg-inline-block text-nowrap"><router-link :to="{ name: 'PublicHome'}" class="nav-link">公共管理</router-link></li>
        <li v-if="$inRole('管理员')||$inRole('用户组管理员')||$inRole('用户组成员')" class="nav-item d-none d-lg-inline-block text-nowrap"><router-link :to="{ name: 'GroupIndex'}" class="nav-link">项目管理</router-link></li>
      </template>
      <template v-else>
        <li class="nav-item d-none d-lg-inline-block text-nowrap"><a class="nav-link"><i class="fas fa-spinner fa-pulse"></i> 加载中…</a></li>
      </template>
    </ul>

    <!-- SEARCH FORM -->
    <!--<form class="form-inline ml-3">
        <div class="input-group input-group-sm">
            <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
            <div class="input-group-append">
                <button class="btn btn-navbar" type="submit">
                    <i class="fa fa-search"></i>
                </button>
            </div>
        </div>
    </form>-->
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Messages Dropdown Menu -->
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="" @click="$store.commit('FETCH_MESSAGE', [...messages])" :title="unreadMessagesCount + ' 条未读消息'">
          <i class="far fa-comments"></i>
          <span v-if="unreadMessagesCount>0" class="badge badge-danger navbar-badge">{{unreadMessagesCount}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right dropdown-menu-sm-center">
          <span class="dropdown-item dropdown-header">{{unreadMessagesCount}} 条未读消息</span>
          <div class="dropdown-divider"></div>
          <template v-for="(message) in messages">
            <router-link :to="{ name: 'Chat', params: { userName: message.fromUser.userName }}" class="dropdown-item">
              <!-- Message Start -->
              <div class="media">
                <img v-if="message.fromUser.avatarUrl" :src="message.fromUser.avatarUrl.replace('/0/0/0/0/', '/100/100/1/0/')" alt="User Avatar" class="img-size-50 mr-3 img-circle">
                <img v-else src="/images/avatar-default.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    {{message.fromUser.nickName}}
                    <span class="float-right badge badge-danger">{{message.count}}</span>
                  </h3>
                  <div class="text-sm text-truncate" style="width:200px;">{{message.text}}</div>
                  <time class="text-sm text-muted"><i class="far fa-clock mr-1"></i>{{$formatTime(message.addTime)}}</time>
                </div>
              </div>
              <!-- Message End -->
            </router-link>
            <div class="dropdown-divider"></div>
          </template>
          <router-link :to="{ name: 'MessageList'}" class="dropdown-item dropdown-footer">查看所有消息</router-link>
        </div>
      </li>
      <!-- Notifications Dropdown Menu -->
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="" :title="unreadNotificationsCount + ' 条未读通知'">
          <i class="far fa-bell"></i>
          <span v-if="unreadNotificationsCount>0" class="badge badge-warning navbar-badge">{{unreadNotificationsCount}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right dropdown-menu-sm-center">
          <span class="dropdown-item dropdown-header">{{unreadNotificationsCount}} 条未读通知</span>
          <div class="dropdown-divider"></div>
          <template v-for="(notice) in notices">
            <router-link :to="{ name: 'NoticeList', query: { type: notice.type }}" class="dropdown-item">
              <span v-if="notice.type=='系统'"><i class="fas fa-bullhorn mr-2"></i>{{notice.count}} 条{{notice.type}}通知</span>
              <span v-else><i class="fas fa-bell mr-2"></i>{{notice.count}} 条{{notice.type}}通知</span>
              <span class="float-right text-muted text-sm">{{$formatTime(notice.addTime)}}</span>
            </router-link>
            <div class="dropdown-divider"></div>
          </template>
          <router-link :to="{ name: 'NoticeList'}" class="dropdown-item dropdown-footer">查看所有通知</router-link>
        </div>
      </li>

      <li class="nav-item dropdown user-menu">
        <a href="" class="nav-link dropdown-toggle text-truncate pr-0" data-toggle="dropdown" style="max-width:120px;" :title="currentUser.nickName">
          <img :src="avatarUrl" class="user-image img-circle elevation-2" alt="User Image">
          <span class="d-none d-md-inline ml-1">{{ currentUser.nickName }}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right dropdown-menu-sm-center">
          <!-- User image -->
          <li class="user-header bg-primary">
            <img :src="avatarUrl" class="img-circle elevation-2" alt="User Image">

            <p class="text-truncate" :title="currentUser.nickName">{{ currentUser.nickName }}</p>
            <small>{{ currentUser.userName }}</small>
          </li>
          <!-- Menu Body -->
          <!--<li class="user-body">
              <div class="row">
                  <div class="col-4 text-center">
                      <a href="#">Followers</a>
                  </div>
                  <div class="col-4 text-center">
                      <a href="#">Sales</a>
                  </div>
                  <div class="col-4 text-center">
                      <a href="#">Friends</a>
                  </div>
              </div>
          </li>-->
          <!-- Menu Footer-->
          <li class="user-footer">
            <router-link to="Profile" class="btn btn-default btn-flat">个人资料</router-link>
            <a class="btn btn-default btn-flat float-right" @click="signOut()">注销</a>
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#">
          <i class="fa fa-th-large"></i>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'va-navibar',
    data() {
      return {
        user: null,
      }
    },
    created() {
      var _this = this
      this.$mainHub.$on('has-new-notice', this.onNoticeChanged)
      this.$mainHub.$on('unread-notice-changed', this.onUnreadNoticeChanged)
      this.$mainHub.$on('unread-message-changed', this.onUnreadMessageChanged)
    },
    beforeDestroy() {
      // Make sure to cleanup SignalR event handlers when removing the component
      this.$mainHub.$off('has-new-notice', this.onNoticeChanged)
      this.$mainHub.$off('unread-notice-changed', this.onUnreadNoticeChanged)
      this.$mainHub.$off('unread-message-changed', this.onUnreadMessageChanged)
    },
    methods: {
      ...mapActions([
        'getNoticesCount',
        'getNotices',
        //'getMessagesCount',
        //'getMessages',
        //'getSetting',
        //'getUser'
      ]),
      signOut() {
        this.$oidc.signOut()
      },
      onNoticeChanged() {
        this.getNoticesCount()
        this.getNotices()
      },
      onUnreadNoticeChanged({ noticeCount, notices }) {
        this.$store.commit('FETCH_NOTICE_COUNT', noticeCount)
        this.$store.commit('FETCH_NOTICE', notices)
      },
      onUnreadMessageChanged({ messageCount, messages }) {
        this.$store.commit('FETCH_MESSAGE_COUNT', messageCount)
        this.$store.commit('FETCH_MESSAGE', messages)
      },
    },
    computed: {
      ...mapGetters([
        'setting',
        'notices',
        'messages',
        'unreadMessagesCount',
        'unreadNotificationsCount',
        'remainTasksCount',
        'currentUser'
      ]),
      avatarUrl() {
        var url = this.currentUser.avatarUrl
        if (url) {
          return url.replace('/0/0/0/0/', '/100/100/1/0/')
        }
        return "/images/avatar-default.jpg"
      }

    }
  }

</script>
